<template lang="html">
    <div class="layout--main" >
      <div class="container mt-12 pb-12">

        <router-link to="/homeInmo">
          <button type="button" class="btn regresar w-48 flex">
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="14" viewBox="0 0 30 22" fill="none">
              <path d="M27.5 22V15.1643C27.5 13.75 26.9722 12.5452 25.9167 11.55C24.8611 10.5548 23.5833 10.0571 22.0833 10.0571H4.75L11.1667 16.1071L9.41667 17.7571L0 8.87857L9.41667 0L11.1667 1.65L4.75 7.7H22.0833C24.25 7.7 26.1111 8.42679 27.6667 9.88036C29.2222 11.3339 30 13.0952 30 15.1643V22H27.5Z" fill="#FF7150"/>
            </svg>
            <span class="ml-4">
              Regresar
            </span>
          </button>
        </router-link>

<div class="row mt-12">
  <div class="col-sm-4">
    <div class="list-group" id="list-tab" role="tablist">
      <a class="list-group-item list-group-item-action active" id="list-perfil-list" data-bs-toggle="list" href="#list-perfil" role="tab" aria-controls="list-perfil">Perfil usuario</a>
      <a class="list-group-item list-group-item-action" id="list-reportes-list" data-bs-toggle="list" href="#list-reportes" role="tab" aria-controls="list-reportes">Reportes</a>
      <a class="list-group-item list-group-item-action" id="list-ejecutivos-list" data-bs-toggle="list" href="#list-ejecutivos" role="tab" aria-controls="list-ejecutivos">Ejecutivos registrados</a>
    </div>
  </div>
  <div class="col-sm-8">
    <div class="tab-content" id="nav-tabContent">
      <div class="tab-pane fade show active bg-datos-perfil p-16" id="list-perfil" role="tabpanel" aria-labelledby="list-perfil-list">
        <div class="row flex flex-row">
          <div class="col-sm-12">
            <label class="mr-8">Nombre de usuario </label>
            <input type="text" class="vs-input " v-model="nombre" readonly></vs-input>
         
          </div>
        </div>
        <div class="row flex flex-row mt-4">
          <div class="col-sm-6">
            <label class="mr-8">Correo </label>
                <input class="vs-input " v-model="correo" readonly></vs-input>
        
          </div>
          <div class="col-sm-6">
            <label class="mr-8">Telefono</label>
               <input class="vs-input" v-model="telefono" readonly></vs-input>
          </div>
        </div>
        <div class="row flex flex-row mt-4">
          <div class="col-sm-6">
            <label class="mr-8">RFC </label>
              <input class="vs-input" v-model="ejecutivoRfc" readonly></vs-input>
          </div>
          <div class="col-sm-6">
            <label class="mr-8">CURP</label>
             <input class="vs-input" v-model="ejecutivoCurp" readonly></vs-input>
          </div>
        </div>

      
        <div v-if="this.logoInmobiliario==null || this.logoInmobiliario==''" class="row flex flex-row mt-4">
          <div class="col-sm-12">
            <label class="mr-8">Logo de oficina </label>
            <div class="row bg-white pl-32 pr-32 pt-16 pb-16 flex flex-row rounded-lg justify-center">
               
                <button class="btn btn-success w-64" @click="seleccionarImg()">Subir archivo</button>
                <input class="vs-input " type="file" hidden id="my_file" @change="obtenerImagen"/>
                <span class="text-center" id="titleInput" >Carga de imagen tipo PNG,JPEG</span>
                <span class="text-center" id="msgError_type" style="color: red; display: none;">{{type_img}}</span>
                <span class="text-center" id="msgError_size" style="color: red;  display: none;">{{ size_img }}</span>
            </div>
          </div>
        </div>

        <div v-if="this.logoInmobiliario" class="row flex flex-row mt-4">
          <div class="col-sm-12">
         
            <label class="mr-8">Imagen</label>
            <div class="row bg-white pl-32 pr-32 pt-16 pb-16 flex flex-row rounded-lg justify-center">
               
                <img :src="'https://cotizador.socasesores.com/uploads/'+activeUserInfo.Imagen" size="70px" class="mr-4 mb-4" />
                <span class="text-center" id="msgError_type" style="color: red; display: none;">{{ type_img }}</span>
                <span class="text-center" id="msgError_size" style="color: red; display: none;">{{ size_img }}</span>

            </div>
          </div>
        </div>


        <div v-if="this.logoInmobiliario" class="row mt-16 flex flex-row justify-end">
            <button type="button" class="btn btn-success w-64"  @click="seleccionarImg()">Editar</button>
             <input class="vs-input " type="file" hidden id="my_file" @change="obtenerImagen"/>             
        </div>
      </div>
  
      <div class="tab-pane fade bg-datos-perfil p-16" id="list-reportes" role="tabpanel" aria-labelledby="list-reportes-list">
        <div class="row mb-8">
            <span>Reportes</span>
        </div>
        
        <div class="row flex flex-row mt-4">
          <div class="col-sm-4">
            <label class="mr-8">Tipo de reporte </label>
            <select name="cars" id="reporte" v-model="reporte" class="vs-input">
              <option :value='tipo.Id' v-for="tipo in reportes"  :key="">{{tipo.Nombre}}</option>
            </select>
          </div>
         
          <div class="col-sm-4">
            <label class="mr-8">Fecha inicio</label>
              <datepicker v-model="fechaInicio" :format="format"></datepicker>
          </div>
          <div class="col-sm-4">
            <label class="mr-8">Fecha termino</label>
              <datepicker v-model="fechaFin" :format="format"></datepicker>
          </div>

        
            
        </div>

        <br>
            <div  class="row">
             <div class="col-sm-4">
               <button class="btn btn-second" @click="getReporte()">Generar reporte</button>
             </div>
            </div>

            <vs-alert title="No hay resultados" color="warning" active="true" class="mt-5 text-warning alerta" v-if="reporte && items.length == 0"></vs-alert>
            <Ejecutivos v-if="reporte=='ReporteGeneralEjecutivos' && items.length" :items="items"/>
            <Organizaciones v-if="reporte=='ReporteGeneralOrganizaciones' && items.length" :items="items"/>
            <Solicitantes v-if="reporte=='ReporteGeneralSolicitantes' && items.length" :items="items"/>
            <Precalificaciones v-if="reporte=='ReporteGeneralPrecalificaciones' && items.length" :items="items"/>
            <Buro v-if="reporte=='ReporteGeneralConsultasBuro' && items.length" :items="items"/>


      </div>




      <div class="tab-pane fade bg-datos-perfil p-16" id="list-ejecutivos" role="tabpanel" aria-labelledby="list-ejecutivos-list">
        <div class="row etiqueta-tabla-ejecutivo">
         <h3> Equipo</h3>
        </div>
<template lang="html">
  <div id="tablaejecutivoperfil" class="mt-12">
    <vs-table  :data="users" max-items="10"
      search
      pagination
     noDataText="No hay datos para mostrar"
                    class="tabla"
                    id="tablaInmo">
     >  

    

      <template slot="thead">
        <vs-th class="tabla-bg-color">
          Ejecutivo
        </vs-th>
        <vs-th class="tabla-bg-color">
          Fecha ingreso
        </vs-th>
        <vs-th class="tabla-bg-color">
          Simulaciones
        </vs-th>
        <vs-th class="tabla-bg-color">
          Contacto
        </vs-th>
      </template>

      <template slot-scope="{data}">
        <vs-tr class="tabla-bg-color" :key="indextr" v-for="(tr, indextr) in data" >

          <vs-td :data="data[indextr].id" >
            {{data[indextr].ejecutivo}}
          </vs-td>
          <vs-td :data="data[indextr].id ">
            {{data[indextr].fecha}}
          </vs-td>          
          <vs-td :data="data[indextr].id">
            {{data[indextr].simulaciones}}
          </vs-td>
          <vs-td :data="data[indextr].id">

            <a :href="`https://api.whatsapp.com/send?phone=${data[indextr].SolicitanteTelefonoCelular}`" target="_blank" class="enlace-icon">
                  <img  class="mr-2 w-6 h-6"  src="@/assets/iconos/wats_icon.png"  @click="EnlaceChat(data[indextr].SolicitanteTelefonoCelular)">       
            </a>

          <a :href="`mailto:${data[indextr].contacto}`" class="enlace-icon">
                <img  class="mr-2 w-6 h-6" src="@/assets/iconos/mail.png"  @click="EnlaceMail(data[indextr].contacto)"> 
          </a>
           
           
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
  </div>
</template>
      </div>
    </div>
  </div>
</div>

</div>
</div>

  

</template>

<script>
import Organizaciones from '@/views/Perfil/ReportesSoc/ReporteOrganizaciones'
import Ejecutivos from '@/views/Perfil/ReportesSoc/ReporteEjecutivos'
import Solicitantes from '@/views/Perfil/ReportesSoc/ReporteSolicitantes'
import Precalificaciones from '@/views/Perfil/ReportesSoc/ReportePrecalificaciones'
import Buro from '@/views/Perfil/ReportesSoc/ReporteBuro'
import Datepicker from 'vuejs-datepicker';

export default {
  components: {
    Datepicker,
    Organizaciones,
    Ejecutivos,
    Solicitantes,
    Precalificaciones,
    Buro

  },
  data () {
    return {
      type_img: 'La imagen tiene que ser formato PNG o JPEG',
      size_img: 'El tamaño máximo es de 3 MB',
      nombre: this.$store.state.AppActiveUser.Nombre,
      correo: this.$store.state.AppActiveUser.EMail,
      contraseña:'',
      telefono:this.$store.state.AppActiveUser.Telefono,
      web:this.$store.state.AppActiveUser.Web,
      ejecutivoRfc:this.$store.state.AppActiveUser.Rfc,
      ejecutivoCurp:this.$store.state.AppActiveUser.Curp,
      img:null,
      key:'',
      oficinaRfc:this.$store.state.AppActiveUser.OficinaRfc,
      oficinaNombre:this.$store.state.AppActiveUser.OficinaNombre,
      oficinaTelefono:this.$store.state.AppActiveUser.OficinaTelefono,
      soc:false,
      inmobiliaria:false,
      socBoton:false,
      logoInmobiliario:null,
      format: "yyyy/MM/dd",
      fechaFin:null,
      fechaInicio:null,
      items:[],
      reporte:'ReporteGeneralEjecutivos',
      reportes:[
        { Nombre:'Ejecutivos', Id:'ReporteGeneralEjecutivos'},
        { Nombre:'Organizaciones', Id:'ReporteGeneralOrganizaciones'},
        { Nombre:'Solicitantes', Id:'ReporteGeneralSolicitantes'},
        { Nombre:'Precalificaciones', Id:'ReporteGeneralPrecalificaciones'},
        { Nombre:'Consultas buró', Id:'ReporteGeneralConsultasBuro'},
      ],
     users:[],
      // allcasos:[]
    /*  users:[
      {
        "id": 1,
        "ejecutivo": "ANA ZAMUDIO IBARRA",
        "fecha": "2019-05-20",
        "simulaciones": "34",
        "contacto": "anali.zamudio7@gmail.com",
        "SolicitanteTelefonoCelular": "5520666600",
      },
      {
        "id": 2,
        "ejecutivo": "Jose Lenin Reyes Cruz",
        "fecha": "2019-01-01",
        "simulaciones": "2",
        "contacto": "operacionestgz.cem@gmail.com",
        "SolicitanteTelefonoCelular": "9612872985",
      },
      {
        "id": 3,
        "ejecutivo": "Liliana Coutiño Popomeya",
        "fecha": "2020-05-12",
        "simulaciones": "5",
        "contacto": "asistentesoc.cem@gmail.com",
         "SolicitanteTelefonoCelular": "9611730904",
      },
      {
        "id": 4,
        "ejecutivo": "Jose Marco Antonio Abraxam  Mendez Sanchez",
        "fecha": "2021-02-01",
        "simulaciones": "1",
        "contacto": "marcomendez.casas@gmail.com",
         "SolicitanteTelefonoCelular": "9611654365",
      },
      {
        "id": 5,
        "ejecutivo": "Edgar Rene Salas Reyes",
        "fecha": "2022-10-11",
        "simulaciones": "1",
        "contacto": "renesalasreyes@gmail.com",
         "SolicitanteTelefonoCelular": "9611890017",
      },
     
    ]*/
  
    }
  },
  computed: {
    activeUserInfo () {
      return this.$store.state.AppActiveUser
    }
  },
  watch:{
    reporte:function(){
      this.items=[]
    },
    telefono:function(newValue) {
      this.telefono=this.telefono.replace(/(\d{2})(\d{4})(\d{4})/, "$1-$2-$3");
    }
  },
  mounted(){
    this.getApikey()
    this.misDatos()
    ///this.ListaAgentesInmobiliarios()

    if(this.$store.state.AppActiveUser.TipoEjecutivoV3=='Inmo'){
      this.inmobiliaria=true
    }else{
      this.soc = true
    }
    if(localStorage.getItem('login')){
      this.socBoton = false
    }else{
      this.socBoton = true
    }
  },
  methods:{
    searchColTable( col ) { 
        let isEnabled = this.ColumnasSelected.find(e => e.label == col);
        return isEnabled;
    },  
    getReporte(){
     
      let formatted_fi = this.fechaInicio.getFullYear() + "-" + (this.fechaInicio.getMonth() + 1) + "-" + this.fechaInicio.getDate()
      let formatted_ff = this.fechaFin.getFullYear() + "-" + (this.fechaFin.getMonth() + 1) + "-" + this.fechaFin.getDate()
      let self=this
      var objRequestReporte = {
        strApiKey: this.key,
        strMetodo: this.reporte,
        objParametros: {
           FechaInicio: formatted_fi,
           FechaFin: formatted_ff,
           UsarGeneral:false
       }
      }
      this.$axios.post('/',objRequestReporte,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.items=response.data.objContenido
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaEstados',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
      
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    numberFormat(num){
      return num.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    seleccionarImg(){
      document.getElementById('my_file').click();
    },
     subirImg(){
      let self=this
      let formData = new FormData();
      formData.append('Imagen', this.img);
      formData.append('MAX_FILE_SIZE','9999999')
      formData.append('request', '{"strApiKey": "'+this.key+'","strMetodo": "RegistraEjecutivo","objEjecutivo": {"EMail": "'+this.correo+'"}}');
      this.$axios.post('/',formData,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.$vs.notify({
              title:`Datos guardados exitosamente`,
              position:'top-right'
            })
            this.$store.commit('UPDATE_USER_INFO', response.data.objContenido)
            this.logoInmobiliario = response.data.objContenido.Imagen            
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en RegistraEjecutivo',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    obtenerImagen(e){      
      this.img = e.target.files[0];
      
      var type = e.target.files[0].type
      var size = e.target.files[0].size      

      if (size/1000000 > 3) {        
        this.logoInmobiliario 
          ? '' : document.getElementById('titleInput').style.display = 'none'

        document.getElementById('msgError_type').style.display = 'none'
        document.getElementById('msgError_size').style.display = 'block'

      }else{        
        this.logoInmobiliario 
          ? '' : document.getElementById('titleInput').style.display = 'none'

        document.getElementById('msgError_size').style.display = 'none'

        if (type.includes('png') || type.includes('jpeg') ) {          

          document.getElementById('msgError_type').style.display = 'none'
          document.getElementById('msgError_size').style.display = 'none'

          this.subirImg()

        }else{          
          document.getElementById('msgError_type').style.display = 'block'          
        }     
      }       
    },
    getApikey(){
      if(!JSON.parse(localStorage.getItem('userInfo')).ApiKey){
        this.$router.push('/login').catch(() => {})
      }else {
        this.key=JSON.parse(localStorage.getItem('userInfo')).ApiKey;
      }
    },
    misDatos(){
      let self=this
      var objRequestBroker = {
        strApiKey: this.key,
        strMetodo: 'MisDatos'
      }
      this.$axios.post('/',objRequestBroker,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            let objBroker=response.data.objContenido;
            this.logoInmobiliario= objBroker.Imagen
       
           
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })

    },
      ListaAgentesInmobiliarios(){
      let self=this
      var objRequestBroker = {
        strApiKey: this.key,
        strMetodo: 'ListaAgentesInmobiliarios'
      }
      this.$axios.post('/',objRequestBroker,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.allcasos=response.data.objContenido;
          
             for (var i = 0; i < this.allcasos.length; i==4) {
                
                this.users.push(this.allcasos[i].Nombre)

             }
       
 
           
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })

    },

  }



/*  data:()=>({

    users:[
      {
        "id": 1,
        "ejecutivo": "Sincere@april.biz",
        "fecha": "Bret",
        "simulaciones": "34",
        "contacto": "hildegard.org",
      },
      {
        "id": 2,
        "ejecutivo": "Sincere@april.biz",
        "fecha": "Bret",
        "simulaciones": "34",
        "contacto": "hildegard.org",
      },
      {
        "id": 3,
        "ejecutivo": "Sincere@april.biz",
        "fecha": "Bret",
        "simulaciones": "34",
        "contacto": "hildegard.org",
      },
      {
        "id": 4,
        "ejecutivo": "Sincere@april.biz",
        "fecha": "Bret",
        "simulaciones": "34",
        "contacto": "hildegard.org",
      },
      {
        "id": 5,
        "ejecutivo": "Sincere@april.biz",
        "fecha": "Bret",
        "simulaciones": "34",
        "contacto": "hildegard.org",
      },
      {
        "id": 6,
        "ejecutivo": "Sincere@april.biz",
        "fecha": "Bret",
        "simulaciones": "34",
        "contacto": "hildegard.org",
      },
      {
        "id": 7,
        "ejecutivo": "Sincere@april.biz",
        "fecha": "Bret",
        "simulaciones": "34",
        "contacto": "hildegard.org",
      },
      {
        "id": 8,
        "ejecutivo": "Sincere@april.biz",
        "fecha": "Bret",
        "simulaciones": "34",
        "contacto": "hildegard.org",
      },
      {
        "id": 9,
        "ejecutivo": "Sincere@april.biz",
        "fecha": "Bret",
        "simulaciones": "34",
        "contacto": "hildegard.org",
      },
      {
        "id": 10,
        "ejecutivo": "Sincere@april.biz",
        "fecha": "Bret",
        "simulaciones": "34",
        "contacto": "hildegard.org",
      }
    ]
  })*/
}

</script>

<style lang="scss" scoped>

.list-group-item.active {
    z-index: 2;
    color: #000;
    background-color: #F2F2F2;
    border-color: #F2F2F2;
    border-radius: 10px;
}
.list-group-item{
    border: 0px;
}

.bg-datos-perfil{
    background-color: #F2F2F2;
}

.layout--main {
    height: 100%;
    min-height: 100%; 
}
.inputfile {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}
.inputfile + label {
    font-weight: 400;
    color: white;
    background-color: #198754;
    display: inline-block;
}

.inputfile:focus + label,
.inputfile + label:hover {
    color: #fff;
    background-color: #198754;
    border-color: #198754;
    text-align: center;

}
.inputfile + label {
	cursor: pointer; /* "hand" cursor */
    text-align: center;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    border-radius: 0.25rem;

}
.inputfile:focus + label {
	outline: 1px dotted #198754;
	outline: -webkit-focus-ring-color auto 5px;
}


.seccion-home-inmo{
    background-color:#F2F2F2;
    border-radius: 7px;
    padding: 15px;
}
.collapse{
  margin-left: 0px !important;
}
.items-filtrado{
  border: solid 1px #8B8B8B;
  width: 79.8%;
  padding: .3rem;
}
.item-configuracion{
  padding-left: .5rem;
  padding-right: .5rem;
  background-color: #F2F2F2;
  align-items: center;
  border-radius: 5px;
  height: 25px;
}
.font-item{
  font-size: 10px;
  text-align: center;
}
.btn-blue {
    color: #fff;
    background-color: #079DEF !important;
    border-color: #079DEF;
    margin-left: 0px !important;
}

.btn-green-configura{
  color: #fff;
  background-color: #4ED176 !important;
  border-color: #4ED176;
  margin-left: 0px !important;
}
.bg-btn-copiar{
  background-color: #8B8B8B;
  color: #fff;
  border: none;
}
.regresar{
  background-color: #fff;
  color: #FF7150;
  border: none;
}
.item-pag:active{
  background-color: #FF7150;
}
.tabla-bg-color{
  background-color:#F2F2F2;
}
</style>


<style>

div#tablaejecutivoperfil .vs-table--tbody-table .tr-values:not(.activeEdit):not(.tr-expandedx):not(.hoverFlat):hover {
    z-index: 200;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
}

div#tablaejecutivoperfil .vs-table--pagination .item-pagination {
    width: 30px;
    height: 30px;
    font-size: .8rem;
    border-radius: 0px;
    border: solid 1px #F2F2F2 !important;
}

div#tablaejecutivoperfil .vs-pagination--li {
    margin: 0px;
    background-color: white !important;
}
div#tablaejecutivoperfil .vs-pagination--ul {
    padding: 0;
    border-radius: 5% !important;
    background-color: white !important;
}
div#tablaejecutivoperfil .vs-pagination-primary .effect, .vs-pagination-primary .vs-pagination--buttons:hover {
    background-color: #4ED176 !important;
}

div#tablaejecutivoperfil .vuesax-app-is-ltr .vs-pagination--buttons.vs-pagination--button-prev, .vuesax-app-is-rtl .vs-pagination--buttons.vs-pagination--button-next {
    background-color: #4ED176;
}

div#tablaejecutivoperfil .vs-pagination--buttons {
  background-color: #4ED176;
}
div#tablaejecutivoperfil .vs-con-table .vs-table--header .vs-table--search .vs-table--search-input {
    padding: 6px 26px !important;
    margin-top: 0px;
}

div#tablaejecutivoperfil .vs-con-table .vs-table--header .vs-table--search i {
    left: 10px;
    margin-top: -26px;
}
div#tablaejecutivoperfil .vs-table--search-input {
    padding: 10px 20px !important;
    border-radius: 5px;
    border: 1px solid #ced4da;
    font-size: .7rem;
    width: 200px;
    color: rgba(0,0,0,.7);
    -webkit-transition: all .25s ease;
    transition: all .25s ease;
    position: absolute;
    margin-top: -10px;
}
div#tablaejecutivoperfil .vs-con-table .vs-table--header .vs-table--search i {
    margin-top: 0px;
}

div#tablaejecutivoperfil .vs-con-table .vs-table--header .vs-table--search{
  margin-top: -26px;
}
.etiqueta-tabla-ejecutivo{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
</style>